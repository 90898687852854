<template>
  <div class="w-100 p-l-30 p-r-30">
    <div v-if="!addUserScreen && !addNewBulkUserScreen && !batchRegistrationUserConfirmationScreen &&
    !batchRegistrationUserSuccessMsgScreen && !tournamentUserListScreen">
      <div v-if="deleteSuccess" class="alert alert-success" role="alert">
        ユーザーが削除されました
      </div>
      <div v-if="deleteFailed" class="alert alert-danger" role="alert">
        This is a success alert—check it out!
      </div>
      <div class="row">
        <div class="col-2 filtering-column140">
          <label for="adminType">管理者</label>
          <select id="adminType" v-model="adminType" :required="true"
                  class="form-control mt-1 filter-select form-select" @change="getData">
            <option v-for="(adminTypeName, adminTypeId) in adminTypes" :value="adminTypeId">{{
                adminTypeName
              }}
            </option>
          </select>
        </div>
        <div class="col-2 filtering-column140">
          <label for="accountStatus">アカウント状態</label>
          <select id="accountStatus" v-model="accountStatus"
                  class="form-control mt-1 filter-select form-select"
                  @change="getData">
            <option v-for="(accountStatusName, accountStatusId) in accountStatuses"
                    :value="accountStatusId">
              {{ accountStatusName }}
            </option>
          </select>
        </div>
        <div class="col-4 filtering-column270">
          <label for="keyword">キーワード</label>
          <div class="input-group mt-1">
            <input id="keyword" v-model="keyword"
                   class="form-control py-2 bg-transparent filter-search-form"
                   placeholder="キーワードを入力"
                   type="search" @keydown.enter="onKeyDown">
            <span class="input-group-append">
              <button class="input-group-text bg-transparent p-2 border-left-radius-0" type="submit" @click="search"><i
                  class="fa fa-search"></i></button>
            </span>
            <div v-show="errors['keyword']" class="form-error">{{ errors['keyword'] }}</div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="width-170">
          <button class="btn custom-button-outline fw-bold d-flex justify-content-around p-l-0"
                  @click="addBulkUser"><img
              src="../assets/images/ic_collective registration.svg">一括新規登録
          </button>
        </div>
        <div class="width-170 float-right">
          <button class="btn custom-button fw-bold" @click="addUser"><i
              class="fas fa-plus float-start"></i>新規登録
          </button>
        </div>
      </div>
      <div class="datatable">
        <Datatable
            :columns="columns"
            :getData="getData"
            :loading="loading"
            :response="response"
            :sortable="true"
            @cta="cta">
        </Datatable>
      </div>
    </div>
    <!-- 11-7 -->
    <div v-else-if="tournamentUserListScreen">
      <div class="container">
        <p class="breadcrumb-menu"><a class="text-decoration-underline cursor-pointer" @click="backToUser">ユーザー管理</a><i
            class="fas fa-angle-right form-control-color"></i> ユーザー情報</p>
        <div class="card border-0 text-first">
          <div class="row d-flex justify-content-between">
            <div class="col-6">
              <i class="fas fa-angle-left"></i><a class="text-decoration-underline cursor-pointer mx-1"
                                                  @click="prevUser">
              {{ prevUserName }}{{ prevUserId === null ? '' : '(' + prevUserId + ')' }}</a>
            </div>
            <div class="col-6 text-end">
              <a class="text-decoration-underline cursor-pointer" @click="nextUser">{{
                  nextUserName
                }}{{ nextUserId === null ? '' : '(' + nextUserId + ')' }}</a><i
                class="fas fa-angle-right mx-1"></i>
            </div>
          </div>
          <div class="card-body px-5 mt-4 m-l-100 m-r-100">
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">入場管理番号</label>
                <input v-model="user.userId" class="form-control mt-2 full-width-input" disabled
                       type="text"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="lastName">姓 名</label>
                <input id="lastName" v-model="user.lastName" class="form-control mt-2 half-width-input"
                       type="text"/>
              </div>
              <div class="col">
                <label for="firstName"></label>
                <input id="firstName" v-model="user.firstName"
                       class="form-control mt-2 half-width-input"
                       placeholder="健介" type="text"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">メールアドレス</label>
                <input v-model="user.email" class="form-control mt-2 full-width-input" type="email"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">生年月日</label>
                <input v-model="user.birthday" class="form-control mt-2 full-width-input" type="text"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label>JTA管理者</label>
                <select class="form-control filter-select-long form-select mt-1">
                  <option :selected="user.isJTA" :value="true">JTA管理者</option>
                  <option :selected="!user.isJTA" :value="false">なし</option>
                </select>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">性別</label>
                <input v-model="user.gender" class="form-control mt-2 full-width-input" type="text"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">国籍</label>
                <input v-model="user.nationalityName" class="form-control mt-2 full-width-input"
                       type="text"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">住所</label>
                <input v-model="user.address" class="form-control mt-2 full-width-input" type="text"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">電話番号</label>
                <input v-model="user.phoneNo" class="form-control mt-2 half-width-input" type="text"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">新型コロナワクチン接種</label>
                <input v-model="coronaVaccinationStatus" class="form-control mt-2 half-width-input"
                       type="text"/>
              </div>
              <div class="col">
                <label for="exampleInputPassword1"></label>
                <input v-model="user.latestCoronaVaccinationDate"
                       :disabled="coronaVaccinationStatus === 'なし'"
                       class="form-control mt-2 half-width-input" type="text"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">PCR検査</label>
                <input v-model="pcrTestResultStatus" class="form-control mt-2 half-width-input"
                       type="text"/>
              </div>
              <div class="col">
                <label for="exampleInputPassword1"></label>
                <input v-model="user.latestPcrTestDate" :disabled="pcrTestResultStatus === '未検査'"
                       class="form-control mt-2 half-width-input" type="text"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col form-check p-l-35">
                <input id="flexCheckDefault" v-model="user.pastAnswersRequired" class="form-check-input"
                       type="checkbox"
                       value=""/>
                <label class="form-check-label" for="flexCheckDefault">
                  過去14日間における回答をさせる
                </label>
              </div>
            </div>
            <div class="row mt-4">
              <!--              TODO: Hide up to PH2-->
              <!--              <div class="col-6">-->
              <!--                <button class="btn disabled btn-outline-primary height-44 w-100 font-weight-bold mx-auto mt-4 visually-hidden">-->
              <!--                  パスワードリセット-->
              <!--                </button>-->
              <!--              </div>-->
              <!--              <div class="col-6">-->
              <!--                <button @click="accountSuspend = !accountSuspend" v-if="accountSuspend" class="btn btn-outline-primary height-44 w-100 font-weight-bold mx-auto mt-4">-->
              <!--                  アカウント停止-->
              <!--                </button>-->
              <!--                <button v-else-if="!accountSuspend" @click="accountSuspend = !accountSuspend" class="btn btn-outline-primary height-44 w-100 font-weight-bold mx-auto mt-4">-->
              <!--                  アカウント停止のキャンセル-->
              <!--                </button>-->
              <!--              </div>-->
            </div>
            <div class="row mt-5">
              <div class="col-5">
                <Modal :back-to-root="backToUser" :data="user" :reload-data="getData"
                       :user-delete="deleteUser"
                       body=" に紐付いているすべての情報が削除され、この操作は後戻りできません。" button-title="削除" cancel-title="キャンセル"
                       confirmation-msg="ユーザーの削除を実行します。 よろしいですか？" ok-title="削除" title="削除"></Modal>
              </div>
              <div class="col-7 text-end">
                <button class="btn no" @click="backToUser">キャンセル</button>
                <button class="btn yes" @click="updateUser">変更</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 11-6 -->
    <div v-else-if="batchRegistrationUserSuccessMsgScreen">
      <div class="container">
        <p><a class="text-decoration-underline cursor-pointer" @click="backToUser">ユーザー管理</a><i
            class="fas fa-angle-right form-control-color"></i> ユーザー一括新規登録</p>
        <div class="p-5 text-first">
          <div class="card-body mx-auto">
            <div class="d-flex flex-column justify-content-center font-16">
              <div v-for="mes in uploadResponseMessage">
                <p>{{ mes }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 11-5 -->
    <div v-else-if="batchRegistrationUserConfirmationScreen">
      <div class="container">
        <p><a class="text-decoration-underline cursor-pointer" @click="backToUser">ユーザー管理</a><i
            class="fas fa-angle-right form-control-color"></i>ユーザー一括新規登録</p>
        <div class="card p-5 text-first">
          <div class="card-body mx-auto">
            <div class="d-flex justify-content-center">
              <label>一括登録を実行します。よろしいですか？</label>
            </div>
            <div class="row mt-4 d-flex justify-content-between">
              <div class="col">
                <button class="btn cancel ml-2 font-weight-bold mx-auto mt-4" @click="addBulkUser">キャンセル
                </button>
              </div>
              <div class="col">
                <button class="btn disable ok font-weight-bold mx-auto mt-4" @click="submitFile">登録
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 11-3 -->
    <div v-else-if="addNewBulkUserScreen">
      <div class="container">
        <p><a class="text-decoration-underline cursor-pointer" @click="backToUser">ユーザー管理</a><i
            class="fas fa-angle-right form-control-color"></i>ユーザー一括新規登録</p>
        <div class="container text-center">
          <div>
            <button class="btn btn-primary height-44 width-350"><img
                src="../assets/images/document-text.png">
              <a class="text-decoration-none text-light" download
                 href="/download/batch_template_users.xlsx">テンプレートファイルのダウンロード</a>
            </button>
          </div>
          <div class="input-group mt-5 width-720 mx-auto">
            <input class="form-control selected-file" placeholder="ファイル選択" type="text"/>
            <span class="input-group-btn"><span class="btn btn-file"><span class="fa fa-upload"></span>
                <input ref="file" accept=".xls, .xlsx" type="file" @change="fileSelected"/></span></span>
          </div>
          <div class="mt-5 row button-container">
            <div class="col">
              <button class="btn cancel width-350" @click="backToUser">キャンセル</button>
            </div>
            <div class="col">
              <button :disabled="Object.keys(this.file).length === 0 && this.file.constructor === Object"
                      class="btn ok width-350" @click="batchRegistrationUserConfirmation">登録
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 11-1 -->
    <!-- 11-2 -->
    <div v-else-if="addUserScreen">
      <div class="container">
        <p><a class="text-decoration-underline cursor-pointer" @click="backToUser">ユーザー管理</a><i
            class="fas fa-angle-right form-control-color"></i> ユーザー新規登録</p>
        <div class="card p-5 text-first">
          <div class="card-body mx-auto">
            <div class="row">
              <div class="col-6">
                <label for="lastName2">姓 名</label>
                <input id="lastName2" v-model="newUser.lastName"
                       class="form-control half-width-input mt-1"
                       placeholder="" type="text"/>
                <div v-show="errors['lastName']" class="form-error">{{ errors['lastName'] }}</div>
              </div>
              <div class="col-6">
                <label for="firstName2"></label>
                <input id="firstName2" v-model="newUser.firstName"
                       class="form-control half-width-input mt-1"
                       placeholder="" type="text"/>
                <div v-show="errors['firstName']" class="form-error">{{ errors['firstName'] }}</div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">メールアドレス</label>
                <input v-model="newUser.email" class="form-control" placeholder="" type="email"/>
              </div>
              <div v-show="errors['email']" class="form-error">{{ errors['email'] }}</div>
            </div>
            <div class="row mt-4 dob-section">
              <label class="p-l-0">生年月日</label>
              <div class="d-inline-flex mt-1 p-l-0 p-r-0">
                <div class="input-group custom-select-year parent-block">
                  <div class="child-block">年</div>
                  <select id="inputGroupSelectYear" v-model="year" class="form-control select_year">
                    <option v-for="year in years" :value="year">
                      {{ year }}
                    </option>
                  </select>
                </div>
                <div class="input-group custom-select-year parent-block">
                  <div class="child-block">月</div>
                  <select id="inputGroupSelectMonth" v-model="month"
                          class="form-control select_month">
                    <option selected value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                </div>
                <div class="input-group custom-select-year parent-block m-r-0">
                  <div class="child-block">日</div>
                  <select id="inputGroupSelectDate" v-model="date" class="form-control select_date">
                    <option selected value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                  </select>
                </div>
              </div>
              <div v-show="errors['birthday']" class="form-error">{{ errors['birthday'] }}</div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label>JTA管理者</label>
                <select v-model="newUser.isJTA"
                        class="form-control filter-select-long form-select mt-1">
                  <option :value="true">JTA管理者</option>
                  <option :selected="true" :value="false">なし</option>
                </select>
              </div>
            </div>
            <div class="row mt-4 d-flex justify-content-between">
              <div class="col">
                <button class="btn cancel ml-2 font-weight-bold mx-auto mt-4" @click="backToUser">キャンセル
                </button>
              </div>
              <div class="col">
                <button class="btn ok font-weight-bold mx-auto mt-4" @click="createUser">登録
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {apiConfig, app, datatable, defaults, gender} from '@/constants'
import Datatable from '../components/datatable/Datatable'
import {mapGetters} from 'vuex'
import {AdminUploadResult} from "../assets/jsadminclient"
import Modal from "../components/modal/Modal";
import Common from "jsadminclient/common";
import Helper from "jsadminclient/helper";

export default {
  name: "Users",
  components: {Datatable, Modal},
  props: {
    defaultResponse: {type: Object, default: null}
  },
  data() {
    return {
      app: app,
      apiConfig: apiConfig,
      adminType: defaults.adminType,
      adminTypes: defaults.adminTypes,
      accountStatus: defaults.accountStatus,
      accountStatuses: defaults.accountStatuses,
      uploadResponseMessage: [],
      keyword: '',
      accountSuspend: true,
      response: {
        sort: 'userId',
        order: 'desc',
        current_page: 1,
        per_page: datatable.default.perPage
      },
      searchKeys: {
        userId: '',
        age: '',
        sex: '',
        country: '',
      },
      deleteSuccess: false,
      deleteFailed: false,
      gender: gender,
      loading: true,
      addUserScreen: false,
      addNewBulkUserScreen: false,
      batchRegistrationUserConfirmationScreen: false,
      batchRegistrationUserSuccessMsgScreen: false,
      tournamentUserListScreen: false,
      columns: datatable.columns.users,
      file: {},
      reloadKey: 1,
      userId: '',
      nextUserId: '',
      nextUserName: '',
      prevUserId: '',
      prevUserName: '',
      user: '',
      newUser: {
        firstName: "",
        lastName: "",
        email: "",
        birthday: "",
        isJTA: false
      },
      coronaVaccinationStatus: "",
      pcrTestResultStatus: "",
      year: "1990",
      month: "01",
      date: "01",
      errors: []
    }
  },
  mounted() {
    this.apiConfig.headers['X-API-Token'] = this.config.token
    this.restoreSearchQuery()
    this.initialPageLoadHandler()
  },
  computed: {
    ...mapGetters(['config']),
    years() {
      const year = new Date().getFullYear();
      return Array.from(
          {length: year - 1919},
          (value, index) => 1919 + (index + 1)
      )
    },
  },
  methods: {
    onKeyDown(event) {
      if (event.keyCode !== 13) {
        return
      }
      this.search()
    },
    initialPageLoadHandler() {
      if (this.defaultResponse) {
        this.response = this.defaultResponse
      }

      Object.assign(this.columns[4], {
        format: (isJtaAdmin) => {
          return isJtaAdmin ? 'JTA管理者' : '-'
        }
      })
      Object.assign(this.columns[5], {
        format: (isTournamentAdmin) => {
          return isTournamentAdmin ? '大会管理者' : '-'
        }
      })
      Object.assign(this.columns[6], {
        format: (details) => {
          return '<i class="fas fa-info-circle font-16 text-info"></i>'
        }
      })

      $(".tooltip").hide()
      this.getData()
    },
    search() {
      this.errors = [];
      // this.response.current_page = 1
      this.getData()
    },
    addUser() {
      this.$router.push({
        name: 'UserRegistration', params: {defaultResponse: this.response},
        query: this.createSearchQuery()
      })
    },
    addBulkUser() {
      this.$router.push({
        name: 'UserBatchRegistration', params: {defaultResponse: this.response},
        query: this.createSearchQuery()
      })
    },
    backToUser() {
      this.addUserScreen = false
      this.addNewBulkUserScreen = false
      this.batchRegistrationUserConfirmationScreen = false
      this.batchRegistrationUserSuccessMsgScreen = false
      this.tournamentUserListScreen = false
    },
    batchRegistrationUserConfirmation() {
      this.batchRegistrationUserConfirmationScreen = true;
      this.batchRegistrationUserSuccessMsgScreen = false;
      this.addNewBulkUserScreen = false;
    },
    batchRegistrationUserSuccessMsg() {
      this.batchRegistrationUserSuccessMsgScreen = true;
      this.addNewBulkUserScreen = false;
      this.batchRegistrationUserConfirmationScreen = false;
    },
    getApiConfig() {
      return new AdminApi.ApiConfig(
          this.apiConfig.basePath,
          this.apiConfig.headers
      )
    },
    async getData() {
      try {
        const api = new AdminApi.JTAUserApi(this.getApiConfig())
        const result = await api.jtaUsersGetAsync(
            this.adminType,
            this.accountStatus,
            this.keyword,
            this.response.sort,
            this.response.order,
            this.response.current_page,
            this.response.per_page
        )
        if (result.items) {
          this.loading = false
          this.reloadKey += 1
          this.response = Helper.formatResponse(result)

          // map user data
          this.response.data = result.items.map(function (item) {
            return {
              userId: item.user.userId,
              lastName: item.user.lastName,
              firstName: item.user.firstName,
              email: item.user.email,
              isJTA: item.user.isJTA,
              isTournamentAdmin: item.isTournamentAdmin,
            }
          })

          Common.updateCurrentPageQuery(this, this.createSearchQuery())
        }

      } catch (e) {
        this.response.data = []
        this.loading = false
        this.errors = Common.getErrorList(e.response, this.errors)
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    cta(key, row) {
      this.$router.push({
        name: 'UserDetail',
        params: {
          userId: row[key],
          accountStatus: row['accountStatus']
        },
        query: this.createSearchQuery()
      })
    },
    fileSelected() {
      this.file = this.$refs.file.files[0];
      $(".selected-file").val(this.$refs.file.files[0].name)
    },
    async submitFile() {
      try {
        const api = new AdminApi.JTAUserApi(this.getApiConfig());
        var result = await api.jtaUsersUploadPostAsync(
            this.file
        );
        if (result) {
          this.uploadResponseMessage.splice(0, this.uploadResponseMessage.length)

          let res = AdminUploadResult.createFromJson(result)
          let mes = res.successCount + "件　登録しました。"
          if (res.updated) {
            this.uploadResponseMessage.push(mes)
          }

          if (res.failureCount > 0) {
            mes = res.failureCount + "件　エラーになりました。"
            this.uploadResponseMessage.push(mes)
          }

          res.failureReasons.forEach((item) => {
            this.uploadResponseMessage.push(item)
          })

          this.batchRegistrationUserSuccessMsg();
        }
      } catch (e) {
        this.loading = false
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    nextUser() {
      this.userId = this.nextUserId
      this.getUser()
    },
    prevUser() {
      this.userId = this.prevUserId
      this.getUser()
    },
    async getUser() {
      try {
        const api = new AdminApi.JTAUserApi(this.getApiConfig())
        const result = await api.jtaUsersUserIdGetAsync(
            this.userId
        )
        this.user = result.user
        this.nextUserId = result.nextUserId
        this.nextUserName = result.nextUserName
        this.prevUserId = result.prevUserId
        this.prevUserName = result.prevUserName
        if (this.user.hasCoronaVaccination) {
          this.coronaVaccinationStatus = "あり (2回目)";
        } else {
          this.coronaVaccinationStatus = "なし, 1回目";
        }
        if (this.user.latestPcrTestResult) {
          this.pcrTestResultStatus = '陽性'
        } else if (!this.user.latestPcrTestResult) {
          this.pcrTestResultStatus = '陰性'
        } else if (this.user.latestPcrTestResult === null) {
          this.pcrTestResultStatus = '未検査'
        }
      } catch (e) {
        this.user = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async updateUser() {
      try {
        const api = new AdminApi.JTAUserApi(this.getApiConfig())
        this.user.hasCoronaVaccination = this.coronaVaccinationStatus === "あり (2回目)";
        var result = await api.jtaUsersUserIdPostAsync(
            this.userId,
            this.user
        );
        this.backToUser()
        this.getData()
      } catch (e) {
        this.loading = false
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async createUser() {
      try {
        this.errors = [];
        var api = new AdminApi.JTAUserApi(this.getApiConfig())
        this.newUser.birthday = this.year + "/" + this.month + "/" + this.date
        var result = await api.jtaUsersPostAsync(
            this.newUser
        )
        this.backToUser()
        this.getData()
      } catch (e) {
        this.loading = false
        this.errors = Common.getErrorList(e.response, this.errors)
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async deleteUser() {
      try {
        var api = new AdminApi.JTAUserApi(this.getApiConfig())
        var result = await api.jtaUsersUserIdDeleteAsync(
            this.userId
        )
        this.deleteSuccess = true
      } catch (e) {
        this.loading = false
        this.deleteFailed = true
        this.backToUser()
        this.getData()
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    createSearchQuery(orgQuery = null) {
      return Common.appendSearchQuery(
          orgQuery,
          {
            adminType: this.adminType,
            accountStatus: this.accountStatus,
            keyword: this.keyword,
            sort: this.response.sort,
            order: this.response.order,
            currentPage: this.response.current_page,
            perPage: this.response.per_page
          })
    },
    restoreSearchQuery() {
      Common.restoreQuery(this, 'adminType', this.$route.query.adminType)
      Common.restoreQuery(this, 'accountStatus', this.$route.query.accountStatus)
      Common.restoreQuery(this, 'keyword', this.$route.query.keyword)
      Common.restoreQuery(this.response, 'sort', this.$route.query.sort)
      Common.restoreQuery(this.response, 'order', this.$route.query.order)
      Common.restoreQuery(this.response, 'per_page', this.$route.query.perPage)
      Common.restoreQuery(this.response, 'current_page', this.$route.query.currentPage)
    },
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb-menu {
  margin-top: 0;
  padding-bottom: 22px;
  margin-bottom: 1rem;
  border-bottom: 2px solid #ECE7E8;
}

.btn-file {
  position: relative;
  overflow: hidden;
  border: 1px solid #F2ECF0;
  height: 48px;
  line-height: 30px;
  border-radius: 0px;
}

.button-container {
  padding: 0px 186px;
}

.fa-upload {
  color: #F2ECF0;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.form-control {
  //background: #F2ECF0;
  border: none;
}

.form-control-admission-number {
  background: #FAF7F9;
  border: none;
  color: #888888;
}

.yes {
  width: 225px;
  height: 44px;
  color: white;
  background-color: #C95D6C;
}

.no {
  width: 225px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #FFFFFF;
}

/*.input-group{*/
/*    width: 720px;*/
/*}*/

::placeholder {
  color: #333333;
}

.custom-button {
  background: #C95D6C;
  color: #FFFFFF;
  border-radius: 4px;
  width: 160px;
}

.custom-button-outline.btn {
  background: #FFFFFF;
  color: #C95D6C;
  border: 1px solid #C95D6C;
  border-radius: 4px;
  width: 160px;
}

.btn {
  &.btn-outline-primary {
    &:hover {
      color: #ffffff !important;
    }
  }
}

.card-container {
  padding: 7rem !important;
  padding-top: 2rem !important;
}

.ok {
  width: 350px;
  height: 44px;
  color: white;
  background-color: #C95D6C;
}

.ok:active {
  color: white;
  background-color: #C95D6C;
}

.cancel {
  width: 350px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #FFFFFF;

  &:focus {
    box-shadow: none !important;
  }
}

.cancel:active {
  color: #C95D6C;
  background-color: #FFFFFF;
}

.delete {
  width: 200px;
  height: 44px;
  color: white;
  background-color: #333333;
}

.delete:active {
  color: white;
  background-color: #333333;
}

.ok-min {
  width: 200px;
  height: 44px;
  color: white;
  background-color: #C95D6C;
}

.ok-min:active {
  color: white;
  background-color: #C95D6C;
}

.cancel-min {
  width: 200px;
  height: 44px;
  color: #C95D6C;
  border: none;
  background-color: #FFFFFF;
}

.cancel-min:active {
  color: #C95D6C;
  background-color: #FFFFFF;
}

a {
  color: #333333;
}

.fas {
  &.fa-plus {
    margin-top: 5px !important;
  }
}

.btn-holo {
  border: 1px solid #C95D6C;
  opacity: 1;
}

.dob-section {
  .parent-block {
    .child-block {
      position: absolute;
      top: 0px;
      background: #F8F8F8;
      justify-content: center;
      align-items: center;
      display: flex;
      border: 1px solid #F0E7ED;
      border-radius: 0px 4px 4px 0px;
      height: 100%;
      max-height: 48px;
      width: 2.25rem;
      z-index: 9999;
      right: 0;
      color: #888888;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }

  .form-control {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
}

.filter-select {
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 32px !important;
}

.filter-select-long {
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 44px !important;

}

.filter-search-form {
  border: 1px solid #DEE2E6 !important;
  height: 32px !important;
}

.custom-select-year {
  width: 28%;
  margin-right: 23px;

  &:first-child {
    width: 40%;
  }

  &:last-child {
    width: 28%;
    margin-right: 0px !important;
  }
}

.content {
  &.users {
    .custom-button {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }

    .custom-button-outline.btn {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }

    .filtering-column140 {
      min-width: 140px;
    }

    .filtering-column270 {
      min-width: 270px;
      max-width: 300px;
    }
  }
}
</style>

<style lang="scss">
.users table {
  display: table;

  tr {
    td {
      /*&:nth-child(6) {*/
      /*  text-align: left;*/
      /*}*/
    }

    th,
    td {
      /*&:nth-child(1), &:nth-child(2), &:nth-child(3) {*/
      /*  width: 120px;*/
      /*}*/

      /*&:nth-child(6) {*/
      /*  width: 172px;*/
      /*}*/

      //&:nth-child(7) {
      //  width: 50px;
      //}

      /*@media only screen and (max-width: 1200px) {*/
      /*  &:nth-child(1) {*/
      /*    width: 123px;*/
      /*  }*/
      /*  &:nth-child(2) {*/
      /*    width: 108px;*/
      /*  }*/
      /*  &:nth-child(3), &:nth-child(4) {*/
      /*    width: 133px;*/
      /*  }*/

      /*}*/
      /*@media only screen and (max-width: 1100px) {*/
      /*  &:nth-child(5) {*/
      /*    width: 58px;*/
      /*  }*/
      /*  &:nth-child(6) {*/
      /*    width: 102px;*/
      /*  }*/
      /*}*/
      @media only screen and (max-width: 1030px) {
        &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
          width: auto;
        }
      }
      @media only screen and (max-width: 768px) {
        &:nth-child(2), &:nth-child(3) {
          width: 70px;
        }
        &:nth-child(4), &:nth-child(5), &:nth-child(6) {
          width: auto;
        }
      }
    }
  }
}
</style>
